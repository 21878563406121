<template>
  <picture
    v-if="image"
    :key="image.id + image.ppoi_tuple"
    class="image-wrapper"
    :class="{ loaded: isLoaded }"
    :style="{
      backgroundColor:
        image.has_transparency || isSvg ? 'unset' : image?.dominant_color
    }"
  >
    <img
      ref="img"
      class="image lazyload"
      :width="width"
      :height="height"
      :class="{
        cover: fit === 'fill',
        contain: fit === 'fit',
        lazyloaded: isLoaded
      }"
      :style="imgPositionStyles"
      :src="isSvg ? image.file : getThumb(image, width, height)"
      :srcset="srcset || undefined"
      :sizes="sizes"
      :alt="image.alt"
      :usemap="usemap"
      v-bind="{ ...(lazyload && { loading: 'lazy' }) }"
      @load="handleLoad"
    />
  </picture>
</template>

<script lang="ts" setup>
import { whenever } from '@vueuse/core'

import { PublicImage } from '@/service/__generated-api'
import { getThumb } from '@/utils/images/imgproxy'

type ResizeType = 'fit' | 'fill' | 'auto'

export type ImgSize = {
  w: number
  h: number
  resize?: ResizeType
}

const props = withDefaults(
  defineProps<{
    image: PublicImage
    width?: number
    height?: number
    colorPlaceholder?: boolean
    imgSizes?: ImgSize[]
    fit?: ResizeType
    usemap?: string
    lazyload?: boolean
    sizes?: string
    alignLeft?: boolean
  }>(),
  {
    width: 0,
    height: 0,
    colorPlaceholder: true,
    imgSizes: () => [],
    fit: 'fill',
    usemap: undefined,
    lazyload: true,
    sizes: undefined
  }
)

const emit = defineEmits<{ (e: 'load'): void }>()

const img = ref<HTMLImageElement>()
const isLoaded = ref(!!img.value?.complete)

const handleLoad = () => {
  isLoaded.value = true
}

watch(img, value => {
  isLoaded.value = !!value?.complete
})

whenever(isLoaded, () => emit('load'), { immediate: true })

const isSvg = computed(() =>
  props.image && props.image.file
    ? props.image.file.toLowerCase().endsWith('.svg')
    : false
)

const srcset = computed(() => {
  if (!props.imgSizes || isSvg.value) {
    return
  }
  const srcs: string[] = []
  props.imgSizes.forEach(size => {
    const w = size.w
    const h = size.h
    srcs.push(`${getThumb(props.image, w, h, size.resize)} ${w}w`)
    srcs.push(`${getThumb(props.image, 2 * w, 2 * h, size.resize)} ${2 * w}w`)
  })
  return srcs.join(',')
})

const sizes = computed(() => {
  if (!srcset.value) return
  return props.sizes
})

const imgPositionStyles = computed(() => {
  if (props.alignLeft) return { objectPosition: '50% 0' }
  const [x, y] = props.image.ppoi_tuple as unknown as [number, number]
  return {
    objectPosition: props.fit === 'fit' ? `50% 50%` : `${x * 100}% ${y * 100}%`
  }
})
</script>

<style lang="scss" scoped>
.image-wrapper,
.image {
  display: block;
  width: 100%;
  height: 100%;
}

.image {
  &.cover {
    object-fit: cover;
  }

  &.contain {
    object-fit: contain;
  }

  &.lazyloaded {
    opacity: 1;
    transition: opacity 0.35s ease-out;
  }
}
</style>
