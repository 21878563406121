import { joinURL, withBase } from 'ufo'

import { PublicImage } from '@/service/__generated-api'

function urlSafeBase64(value: string) {
  const base64 = process.server
    ? Buffer.from(value).toString('base64')
    : window.btoa(value)

  return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export const getThumb = (
  image: PublicImage,
  width: number,
  height = 0,
  resize: 'fit' | 'fill' | 'auto' = 'fill'
): string => {
  if (!image?.file) {
    return ''
  }

  const encodedUrl = urlSafeBase64(image.file)
  const enlarge: 0 | 1 = 1
  const [x, y] = image.ppoi_tuple
  const gravity = `fp:${x}:${y}`
  const path = `/rs:${resize}:${Math.round(width)}:${Math.round(
    height
  )}:${enlarge}/g:${gravity}/${encodedUrl}`

  const baseURL = import.meta.env.VITE_IMGPROXY_URL
  return withBase(joinURL('_', path), baseURL)
}
